// ------------ import external dependencies -----------
import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

// ----------- import internal dependencies -------------
import AuthLayout from "../layouts/AuthLayout";
import AdminLayout from "../layouts/AdminLayout";
import ErrorBoundary from "../components/ErrorBoundary";
const SignIn = lazy(() => import("../screens/auth/signin"));
const OTP = lazy(() => import("../screens/auth/otp"));
const Dashboard = lazy(() => import("../screens/app/dashboard"));
const AccessManagement = lazy(() => import("../screens/app/accessManagement"));
const Partners = lazy(() => import("../screens/app/accessManagement/partners"));
const Reports = lazy(() => import("../screens/app/reports"));
const UserData = lazy(() => import("../screens/app/userManagement/userData"));
const UserRank = lazy(() => import("../screens/app/userManagement/userRank"));
const UserEmails = lazy(() => import("../screens/app/userManagement/userEmails"));
const Referrals = lazy(() => import("../screens/app/userManagement/referrals"));
const NegativeSummation = lazy(() => import("../screens/app/userManagement/negativeSummation"));
const ManualRewards = lazy(() => import("../screens/app/userManagement/manualRewards"));
const CustomerRetention = lazy(() => import("../screens/app/userManagement/customerRetention"));
const KycData = lazy(() => import("../screens/app/userManagement/kycData"));
const FlaggedUsers = lazy(() => import("../screens/app/userManagement/flagged"));
const DeletedUsers = lazy(() => import("../screens/app/userManagement/deleted"));
const UserProfile = lazy(() => import("../screens/app/userManagement/userProfile"));
const BalanceEdits = lazy(() => import("../screens/app/balanceEdits"));
const RatesManagement = lazy(() => import("../screens/app/ratesManagement"));
const RatesTargets = lazy(() => import("../screens/app/ratesManagement/targets"));
const CategoryManagement = lazy(() => import("../screens/app/categoryManagement"));
const AllQCP = lazy(() => import("../screens/app/qcpManagement/all"));
const ApprovedQCP = lazy(() => import("../screens/app/qcpManagement/approved"));
const RejectedQCP = lazy(() => import("../screens/app/qcpManagement/rejected"));
const FlaggedQCP = lazy(() => import("../screens/app/qcpManagement/flagged"));
const ClearedQCP = lazy(() => import("../screens/app/qcpManagement/cleared"));
const ClearedApprovedQCP = lazy(() => import("../screens/app/qcpManagement/clearedApproved"));
const ClearedRejectedQCP = lazy(() => import("../screens/app/qcpManagement/clearedRejected"));
//---Transaction routes -------
const AllTransactions = lazy(() => import("../screens/app/transactionMgt/all"));
const ArchivedTransactions = lazy(() => import("../screens/app/transactionMgt/archived"));
const CompletedTransactions = lazy(() => import("../screens/app/transactionMgt/completed"));
const RejectedTransactions = lazy(() => import("../screens/app/transactionMgt/rejected"));
const PendingTransactions = lazy(() => import("../screens/app/transactionMgt/pending"));
const PartnerProcessing = lazy(() => import("../screens/app/transactionMgt/partnerProcessing"));
const TransactionRatings = lazy(() => import("../screens/app/transactionMgt/transactionRatings"));
const RejectionReason = lazy(() => import("../screens/app/transactionMgt/transactionRxReason"));
// ------ vba routes -------
const AllVba = lazy(() => import("../screens/app/vba/all"));
const SettledVba = lazy(() => import("../screens/app/vba/settled"));
const DeclinedVba = lazy(() => import("../screens/app/vba/declined"));
const FlaggedVba = lazy(() => import("../screens/app/vba/flagged"));
const ResolveVba = lazy(() => import("../screens/app/vba/resolve"));

const PendingPayment = lazy(() => import("../screens/app/paymentManagement/pending"));
const PendingPaymentGt50 = lazy(() => import("../screens/app/paymentManagement/pending+50"));
const PendingPaymentLess50 = lazy(() => import("../screens/app/paymentManagement/pending-50"));
const RejectedPayment = lazy(() => import("../screens/app/paymentManagement/rejected"));
const ApprovedPayment = lazy(() => import("../screens/app/paymentManagement/approved"));
const FailedPayment = lazy(() => import("../screens/app/paymentManagement/failed"));
const ProcessingPayment = lazy(() => import("../screens/app/paymentManagement/processing"));
const PaymentSummary = lazy(() => import("../screens/app/paymentManagement/paymentSummary"));
const PaymentRejection = lazy(() => import("../screens/app/paymentManagement/paymentRxReason"));
const PurchaseTransactions = lazy(() => import("../screens/app/purchase"));
const Notifications = lazy(() => import("../screens/app/notifications"));
const SiteManagement = lazy(() => import("../screens/app/settings/siteManagement"));
const FeeManagement = lazy(() => import("../screens/app/settings/feeManagement"));
const ContentManagement = lazy(() => import("../screens/app/settings/contentManagement"));
const QuickSettings = lazy(() => import("../screens/app/settings/quickSettings"));
// ------- bills paths -------
const BillsHistory = lazy(() => import("../screens/app/bills/history"));
const BillsOptions = lazy(() => import("../screens/app/bills/option"));

// ------ Just Gadgets paths -------
const AllGadgets = lazy(() => import("../screens/app/gadgets/all"));
const Confirmed = lazy(() => import("../screens/app/gadgets/confirmed"));
const Shipped = lazy(() => import("../screens/app/gadgets/shipped"));
const Delivered = lazy(() => import("../screens/app/gadgets/delivered"));
const AvailableProducts = lazy(() => import("../screens/app/gadgets/availableProducts"));

// ------ virtuals cards path ------
const CardCreation = lazy(() => import("../screens/app/cards/cardCreation"));
const CardTransactions = lazy(() => import("../screens/app/cards/cardTransactions"));

// -------- fallback paths -------
const NotFound = lazy(() => import("../screens/fallbacks/notFound"));

export const adminLayoutChildren = [
  {
    path: "dashboard",
    index: true,
    element: <Dashboard />,
    title: "Overview",
  },
  {
    path: "access-management",
    children: [
      {
        path: "users",
        element: <AccessManagement />,
        title: "Access Management - Users List",
      },
      {
        path: "partners",
        element: <Partners />,
        title: "Access Management - Partners List",
      },
    ],
  },
  {
    path: "reports",
    element: <Reports />,
    title: "Reports",
  },
  {
    path: "user-management",
    children: [
      {
        path: "users",
        element: <UserData />,
        title: "User Management - User Data",
      },
      {
        path: "users/:userId",
        element: <UserProfile />,
        title: "User Management - User Profile",
      },
      {
        path: "user-rank",
        element: <UserRank />,
        title: "User Management - User Rank",
      },
      {
        path: "user-emails",
        element: <UserEmails />,
        title: "User Management - User Emails",
      },
      {
        path: "referrals",
        element: <Referrals />,
        title: "User Management - Referrals",
      },
      {
        path: "negative-summation",
        element: <NegativeSummation />,
        title: "User Management - Negative Summation",
      },
      {
        path: "manual-rewards",
        element: <ManualRewards />,
        title: "User Management - Manual Rewards",
      },
      {
        path: "crd",
        element: <CustomerRetention />,
        title: "User Management - Customer Retention",
      },
      {
        path: "kyc",
        element: <KycData />,
        title: "User Management - KYC Data",
      },
      {
        path: "flagged",
        element: <FlaggedUsers />,
        title: "User Management - Flagged Users",
      },
      {
        path: "deleted",
        element: <DeletedUsers />,
        title: "User Management - Deleted Users",
      },
    ],
  },
  {
    path: "balance-edits",
    element: <BalanceEdits />,
    title: "Balance Edits",
  },
  {
    path: "rates",
    children: [
      {
        path: "card-rates",
        element: <RatesManagement />,
        title: "Rates Management",
      },
      {
        path: "rates-targets",
        element: <RatesTargets />,
        title: "Rates Targets",
      },
    ],
  },
  {
    path: "category-management",
    element: <CategoryManagement />,
    title: "Category Management",
  },
  {
    path: "qcp-management",
    children: [
      {
        path: "all",
        element: <AllQCP />,
        title: "QCP Management - All QCP",
      },
      {
        path: "approved",
        element: <ApprovedQCP />,
        title: "QCP Management - Approved QCP",
      },
      {
        path: "rejected",
        element: <RejectedQCP />,
        title: "QCP Management - Rejected QCP",
      },
      {
        path: "flagged",
        element: <FlaggedQCP />,
        title: "QCP Management - Flagged QCP",
      },
      {
        path: "cleared",
        element: <ClearedQCP />,
        title: "QCP Management - Cleared QCP",
      },
      {
        path: "cleared-approved",
        element: <ClearedApprovedQCP />,
        title: "QCP Management - Cleared-Approved",
      },
      {
        path: "cleared-rejected",
        element: <ClearedRejectedQCP />,
        title: "QCP Management - Cleared-Rejected",
      },
    ],
  },
  {
    path: "transaction-management",
    children: [
      { path: "all", title: "Transaction Management - All Orders", element: <AllTransactions /> },
      { path: "archived", title: "Transaction Management - Archived Orders", element: <ArchivedTransactions /> },
      {
        path: "completed",
        title: "Transaction Management - Completed Transactions",
        element: <CompletedTransactions />,
      },
      {
        path: "rejected",
        title: "Transaction Management - Rejected Transactions",
        element: <RejectedTransactions />,
      },
      {
        path: "pending",
        title: "Transaction Management - Pending Transactions",
        element: <PendingTransactions />,
      },
      {
        path: "partner-processing",
        title: "Transaction Management - Partner Processing",
        element: <PartnerProcessing />,
      },
      {
        path: "rejected-reason",
        title: "Transaction Management - Rejected Reason",
        element: <RejectionReason />,
      },
      {
        path: "transaction-ratings",
        title: "Transaction Management - Transaction Ratings",
        element: <TransactionRatings />,
      },
    ],
  },
  {
    path: "vba",
    children: [
      { path: "all", title: "VBA Management - All Top-Ups", element: <AllVba /> },
      { path: "settled", title: "VBA Management - Settled Top-Ups", element: <SettledVba /> },
      { path: "declined", title: "VBA Management - Settled Top-Ups", element: <DeclinedVba /> },
      { path: "flagged", title: "VBA Management - Flagged Top-Ups", element: <FlaggedVba /> },
      { path: "resolve", title: "VBA Management - Resolve Top-Ups", element: <ResolveVba /> },
    ],
  },
  {
    path: "payment-management",
    children: [
      { path: "pending/all", title: "Payment Management - Pending Payment", element: <PendingPayment /> },
      {
        path: "pending/pending+50",
        title: "Payment Management - Pending Payment GT50",
        element: <PendingPaymentGt50 />,
      },
      {
        path: "pending/pending-50",
        title: "Payment Management - Pending Payment GT-50",
        element: <PendingPaymentLess50 />,
      },
      {
        path: "rejected",
        title: "Payment Management - Rejeceted Payment",
        element: <RejectedPayment />,
      },
      {
        path: "approved",
        title: "Payment Management - Approved Payment",
        element: <ApprovedPayment />,
      },
      {
        path: "failed",
        title: "Payment Management - Failed Payment",
        element: <FailedPayment />,
      },
      {
        path: "processing",
        title: "Payment Management - Processing Payment",
        element: <ProcessingPayment />,
      },
      {
        path: "reasons",
        title: "Payment Management - Rejection Reasons",
        element: <PaymentRejection />,
      },
      {
        path: "payment-summary/:userId",
        title: "Payment Management - Payment Summary",
        element: <PaymentSummary />,
      },
    ],
  },
  {
    path: "notifications",
    title: "Notifications",
    element: <Notifications />,
  },
  {
    path: "settings",
    children: [
      {
        path: "site-management",
        title: "Site Management",
        element: <SiteManagement />,
      },
      {
        path: "fee-management",
        title: "Fee Management",
        element: <FeeManagement />,
      },
      {
        path: "content-management",
        title: "Content Management",
        element: <ContentManagement />,
      },
      {
        path: "bills-management",
        title: "Bills Management",
        element: <BillsOptions />,
      },
      {
        path: "quick-settings",
        title: "Quick Settings",
        element: <QuickSettings />,
      },
    ],
  },
  {
    path: "other-transactions",
    children: [
      {
        path: "bills-history",
        title: "Bills History",
        element: <BillsHistory />,
      },
      {
        path: "purchase",
        title: "Gift Card Purchase",
        element: <PurchaseTransactions />,
      },
      {
        path: "just-gadgets/all",
        title: "All Deliveries",
        element: <AllGadgets />,
      },
      {
        path: "just-gadgets/shipped",
        title: "All Deliveries",
        element: <Shipped />,
      },
      {
        path: "just-gadgets/confirmed",
        title: "All Deliveries",
        element: <Confirmed />,
      },
      {
        path: "just-gadgets/delivered",
        title: "All Deliveries",
        element: <Delivered />,
      },
      {
        path: "just-gadgets/available-products",
        title: "Available Products",
        element: <AvailableProducts />,
      },
      {
        path: "cards/creation",
        title: "Card Creation",
        element: <CardCreation />,
      },
      {
        path: "cards/transactions",
        title: "Card Creation",
        element: <CardTransactions />,
      },
    ],
  },
];

export const authRoutes = createBrowserRouter([
  {
    path: "/",
    element: <AuthLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/signin",
        element: <SignIn />,
        index: true,
      },
      {
        path: "/otp",
        element: <OTP />,
        index: true,
      },
      {
        path: "/getlost",
        element: null,
        index: true,
      },
    ],
  },
  {
    path: "/admin",
    element: <AdminLayout />,
    errorElement: <ErrorBoundary />,
    children: [...adminLayoutChildren],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
